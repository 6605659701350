import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'
import timezone from 'dayjs/plugin/timezone.js'
// import * as plugin from 'dayjs/plugin'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Moscow')

import {
  monthNamesShort,
  monthNames,
  weekDayNames,
  shortWeekDayNames
} from '@/modules/constants/month_names'

export function getDate(date) {
  return dayjs(date).tz('Europe/Moscow').get('date')
}

export function getTime(date) {
  return `${dayjs(date).tz('Europe/Moscow').format('HH:mm')}`
}

export function getDayOfWeek(date) {
  return weekDayNames[dayjs(date).tz('Europe/Moscow').day()]
}

export function getShortDayOfWeek(date) {
  return shortWeekDayNames[dayjs(date).tz('Europe/Moscow').day()]
}

export function getMonth(date) {
  return monthNames[dayjs(date).tz('Europe/Moscow').get('month')]
}

export function getMonthNumber(date) {
  return dayjs(date).tz('Europe/Moscow').format('MM')
}

export function getShortMonth(date) {
  return monthNamesShort[dayjs(date).tz('Europe/Moscow').get('month')]
}

export function getYear(date) {
  return dayjs(date).tz('Europe/Moscow').get('year')
}

export function format(date, string) {
  return dayjs(date).tz('Europe/Moscow').format(string)
}
